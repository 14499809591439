import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth/auth";
import htmlClass from "./modules/htmlclass/htmlclass";
import config from "./modules/config/config";
import breadcrumbs from "./modules/breadcrumbs/breadcrumbs";
import domains from "./modules/domains/domains";
import rules from "./modules/rules/rules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    domains,
    rules,
  },
});
