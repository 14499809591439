<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <!--          <KTSubheader-->
          <!--            v-if="subheaderDisplay"-->
          <!--            v-bind:breadcrumbs="breadcrumbs"-->
          <!--            v-bind:title="pageTitle"-->
          <!--          />-->
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid pt-4">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <div class="d-lg-flex flex-row-fluid">
                <!-- begin:: Aside Left -->
                <KTAside v-if="asideEnabled"></KTAside>
                <!-- end:: Aside Left -->

                <div class="content-wrapper flex-row-fluid">
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
// import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTScrollTop from "@/view/layout/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/modules/htmlclass/htmlclass.js";
import { buildFilterString } from "@/core/services/services";
import { SET_PAGE } from "@/core/services/store/modules/page/types";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(`htmlclass/${ADD_BODY_CLASSNAME}`, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(
        `htmlclass/${REMOVE_BODY_CLASSNAME}`,
        "page-loading"
      );
    }, 2000);
  },
  methods: {
    ...mapMutations({
      initPage: `page/${SET_PAGE}`,
    }),

    changePage: function () {
      let filter = this.$route.query;

      this.initPage({
        filter: { ...filter },
        page_name: this.$route.name,
      });
    },

    footerLayout(type) {
      return this.layoutConfig("footer.layout") === type;
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      breadcrumbs: "breadcrumbs/breadcrumbs",
      pageTitle: "breadcrumbs/pageTitle",
      layoutConfig: "config/layoutConfig",
    }),

    ...mapState({
      filter: (state) => state.page.filter,
      default_filter: (state) => state.page.default_filter,
    }),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.name !== oldValue.name) {
        this.changePage();
      }
    },
    filter(newValue) {
      if (
        !this.default_filter &&
        buildFilterString(newValue) !== buildFilterString(this.$route.query)
      ) {
        this.$router.push({ query: newValue });
      }
    },
  },
  created: function () {
    this.changePage();
  },
};
</script>
